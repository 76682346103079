<template>
  <div class="indexPageWrapper">
    <!-- 轮播图 -->
    <el-row class="bannerRow">
      <el-carousel height="480px" :autoplay="true">
        <el-carousel-item key="1">
          <img class="itemImg" src="@/assets/img/1.png" />
          <el-row class="docRow">
            <h2>开放平台升级</h2>
            <div class="detailDiv">
              全新的文档中心提供更多API接口信息，增加在线开发工具方便开发者调试和获取环境数据
            </div>
          </el-row>
        </el-carousel-item>
        <el-carousel-item key="2">
          <img class="itemImg" src="@/assets/img/2.png" />
          <el-row class="docRow">
            <h2>API官方群</h2>
            <div class="detailDiv">
              开放平台的官方群组开通了！
              为快速响应开发人员的咨询，解决您在开发过程中的各种问题和异常，请您申请加入官方群组。 
              群组内会第一时间公布更新、发布通告，接收您的宝贵建议、需求，并安排有技术人员跟进您开发过程中的问题进行处理，赶紧来吧，期待您的加入。
            </div>
          </el-row>
        </el-carousel-item>
        <el-carousel-item key="3">
          <img class="itemImg" src="@/assets/img/3.png" />
          <el-row class="docRow">
            <h2>销售管理一步到位</h2>
            <div class="detailDiv">
              发现店铺、商品、指标数据中隐含的秘密，辅助决策提升绩效
            </div>
          </el-row>
        </el-carousel-item>
      </el-carousel>
    </el-row>
    <!-- 内容区域 -->
    <el-row class="contentRow">
      <el-col class="contentItem">
        <div class="imgArea">
          <img src="@/assets/img/content1.png" />
        </div>
        <div class="textArea">
          <h3>运营管理</h3>
          <div class="textDetail">
            <ul>
              <li>基于Listing提供断货预警，直观展示断货日期，快速反应减低损失</li>
              <li>支持销售查看Listing销量，当日、昨日、上周同日，随时掌握最新销售情况</li>
              <li>提供Listing关键指标（销量、评分、BSR、退货、流量、广告）一屏展示，快速发现销量变动原因</li>
              <li>支持广告投放、分时调价、广告指标调价 免费索赔，提供无偿索赔建议</li>
            </ul>
          </div>
        </div>
      </el-col>
      <el-col class="contentItem">
        <div class="textArea">
          <h3>数据统计</h3>
          <div class="textDetail">
            <ul>
              <li>支持日常业务处理如采购应付、供应商应付、单据变更审核</li>
              <li>自动统计亚马逊订单利润、亚马逊回款记录、实际到账记录</li>
              <li>自动统计亚马逊进销存及其货值</li>
            </ul>
          </div>
        </div>
        <div class="imgArea">
          <img src="@/assets/img/content2.png" />
        </div>
      </el-col>
      <el-col class="contentItem">
        <div class="imgArea">
          <img src="@/assets/img/content3.png" />
        </div>
        <div class="textArea">
          <h3>多仓备货</h3>
          <div class="textDetail">
            <ul>
              <li>汇总运营备货需求，提供销量分析界面，辅助采购分析</li>
              <li>同步多海外仓在库、头程在途以及本地仓采购在途数据，一站式管理多仓库存，精准分析备货数量</li>
              <li>对接多个海外仓入库单，一键推送，减少多余操作</li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
    
  </div>
</template>

<script>
export default {
  name: 'indexPage',
  data(){
    return {
      backgroundList:[
        {src: '@/assets/img/1.png' },
        {src: '@/assets/img/2.png' },
        {src: '@/assets/img/3.png' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .indexPageWrapper{

    .bannerRow{
      // height: 480px;
      padding: 0 80px;
      padding-top: 40px;

      .el-carousel{
        // height: 100%;

        .el-carousel__container{
          // height: 100%;

          .el-carousel__item{
            width: 100%;

            .itemImg{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .docRow{
              position: absolute;
              z-index: 2;
              top: 60px;
              right: 20%;

              width: 60%;

              color: white;
              line-height: 28px;
              
              
            }
            
          }
          
        }
        
      }
      
    }

    .contentRow{
      padding-top: 40px;
      padding-left: 80px;
      padding-right: 80px;

      .contentItem{
        display: flex;

        .textArea,.imgArea{
          width:50%;
          padding-left: 120px;
          box-sizing: border-box;
          text-align: left;
        }

        // .imgArea{
          // img{
          //   width: 100%;
          //   height: 400px;
          // }
        // }

        .textArea{
          ul{
            padding-left: 1em;

            li{
              line-height: 28px;
            }
          }

        }
        
      }
      
    }
    
  }
</style>